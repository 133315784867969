

.htCellBGWhite{
    background-color: #fff !important;
}

.htCellBGGreen{
    background-color: #ccfdcf !important;
}

.htCellBGRed{
    background-color: #fcd4d4 !important;
}

.htCellBGYellow{
    background-color: #fdf4bf !important;
}

.htCellBGPurple{
    background-color: #d3bdfd !important;
}

.htCellBGBlue{
    background-color: #b2d2fc !important;
}

.htEllipsisText{
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}

.htImageEditor {
    position: absolute;
    width: auto;
    background-color: #cbddf8;
    align-items: center;
    text-align: center;
}

.htImageEditor > img{
    height: 100%;
    max-width: 100%;
    width: auto;
    display: block;
    margin: auto;
}

.htEllipsisText{
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}

.htContextMenu:not(.htGhostTable){
    z-index: 9999 !important;
}

.htDropdownMenu:not(.htGhostTable){
    z-index: 3060 !important;
}

.htFiltersConditionsMenu:not(.htGhostTable){
    z-index: 3070 !important;
}

.ht_clone_master{
    z-index: 130 !important;
}