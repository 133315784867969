
/*.column() {*/
/*    align-items: center;*/
/*    border-right: @border;*/
/*    display: flex;*/
/*    flex: 1;*/
/*    height: 100%;*/
/*    min-width: 0;*/

/*&:last-child {*/
/*    border-right: none;*/
/*}*/
/*}*/

/*** Overrides for react-virtualized styles ***/
.ReactVirtualized__Table__Grid {
    background-color: white;
    border-bottom: 1px solid #D3D3D3;;
    border-right: 1px solid #D3D3D3;;
}
.ReactVirtualized__Table__headerColumn {
    /*.column();*/
    margin-right: 1px;
}
.ReactVirtualized__Table__headerRow {
    background-image: linear-gradient(#fff, #efefef);
    border: 1px solid #D3D3D3;;
    padding-right: 0 !important;
}
.ReactVirtualized__Table__row {
    border-bottom: 1px solid #D3D3D3;;
    border-left: 1px solid #D3D3D3;;
}
.ReactVirtualized__Table__rowColumn {
    margin-right: 1px;
    align-self: start;
    height: 100%
}